import request from '@/utils/request'

const take_inventoryApi = {
  take_inventory_list: '/take_inventory/',
  take_inventory_create: '/take_inventory/',
  take_inventory_read: '/take_inventory/',
  take_inventory_delete: '/take_inventory/'
}

/**
 * 获取授权车辆列表
 */
export function take_inventory_list (parameter) {
  return request({
    url: take_inventoryApi.take_inventory_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function take_inventory_create (parameter) {
  return request({
    url: take_inventoryApi.take_inventory_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 详情
 */
export function take_inventory_read (parameter, id) {
  return request({
    url: take_inventoryApi.take_inventory_read + id + '/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 删除
 */
export function take_inventory_delete (take_inventory_id) {
  return request({
    url: take_inventoryApi.take_inventory_delete + take_inventory_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
