<template>
  <div>
    <a-form :form="form" v-bind="formLayout">
      <a-row :gutter="[16,16]">
        <a-col :span="24">
          <a-form-item label="快递订单接口代码">
            <a-input
              v-decorator="['client_key', {rules: [{required: true, message: '必填项，请填写信息'}, {max:11, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="'快递订单接口密钥">
            <a-input
              v-decorator="['client_secret', {rules: [{required: true, message: '必填项，请填写信息'}, {max:43, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="申报接口代码">
            <a-input
              v-decorator="['client_1210_key', {rules: [{required: true, message: '必填项，请填写信息'}, {max:11, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="'申报接口密钥">
            <a-input
              v-decorator="['client_1210_secret', {rules: [{required: true, message: '必填项，请填写信息'}, {max:43, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="客户编码">
            <a-input
              v-decorator="['customer_code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:11, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="'客户密钥">
            <a-input
              v-decorator="['customer_secret', {rules: [{required: true, message: '必填项，请填写信息'}, {max:8, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="订单创建接口">
            <a-input
              v-decorator="['create_url', {rules: [{required: true, message: '必填项，请填写信息'}, {max:150, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="订单申报接口">
            <a-input
              v-decorator="['declare_url', {rules: [{required: true, message: '必填项，请填写信息'}, {max:150, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="轨迹查询接口">
            <a-input
              v-decorator="['track_query_url', {rules: [{required: true, message: '必填项，请填写信息'}, {max:150, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="发件人姓名">
            <a-input
              v-decorator="['send_name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="发件人电话">
            <a-input
              v-decorator="['send_mobile', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="发件人地址">
            <a-input
              v-decorator="['send_address', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col span="8"></a-col>
        <a-col span="8">
          <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
        </a-col>
        <a-col span="8">
          <a-button type="danger" @click="deleteSto" v-if="showDelete">删除</a-button>
        </a-col>
      </a-row>
    </a-form>
    <a-modal
      title="提示"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <p>确定删除吗？</p>
    </a-modal>
  </div>
</template>

<script>
import pick from 'lodash.pick'
import {
  wms_yto_config,
  wms_yto_config_create,
  wms_yto_config_delete,
  wms_yto_config_update
} from '@/api/logistics'

// 表单字段
const fields = ['client_key', 'client_secret', 'client_1210_key', 'client_1210_secret', 'customer_code', 'customer_secret', 'create_url', 'declare_url', 'track_query_url', 'send_name', 'send_mobile', 'send_address']
export default {
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      showDelete: false,
      model: {},
      yto_id: -1,
      confirmLoading: false,
      visible: false
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
    this.getSto()
  },
  methods: {
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        console.log('我要提交接口', values)
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
          if (this.yto_id !== -1) {
            wms_yto_config_update(values, this.yto_id).then(res => {
              console.log(res)
              this.model = res.data
              if (res.data) {
                this.yto_id = this.model.id
                this.showDelete = true
              } else {
                this.showDelete = false
              }
            })
          } else {
            wms_yto_config_create(values).then(res => {
              console.log(res)
              this.model = res.data
              if (res.data) {
                this.yto_id = this.model.id
                this.showDelete = true
              } else {
                this.showDelete = false
              }
            })
          }
        }
      })
    },
    deleteSto () {
      this.visible = true
    },
    getSto () {
      wms_yto_config().then(res => {
        console.log('---->>>', res)
        this.model = res.data
        if (res.data) {
          this.yto_id = this.model.id
          this.showDelete = true
        } else {
          this.showDelete = false
        }
      })
    },
    handleOk (e) {
      this.confirmLoading = true
      wms_yto_config_delete(this.yto_id).then(res => {
        console.log(res)
        if (res.code === 1000) {
          this.confirmLoading = false
          this.visible = false
          this.showDelete = false
          this.form.resetFields()
        }
      })
    },
    handleCancel (e) {
      this.visible = false
    }
  }
}
</script>
