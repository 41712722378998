import request from '@/utils/request'

const appletApi = {
  sto: '/wms/sto/config/',
  yto: '/wms/yto/config/'
}

/**
 * 创建申通你配置
 */
export function wms_sto_config_create (parameter) {
  return request({
    url: appletApi.sto,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 创建圆通你配置
 */
export function wms_yto_config_create (parameter) {
  return request({
    url: appletApi.yto,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 获取申通配置
 */
export function wms_sto_config () {
  return request({
    url: appletApi.sto,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 获取圆通配置
 */
export function wms_yto_config () {
  return request({
    url: appletApi.yto,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 更新申通配置
 */
export function wms_sto_config_update (parameter, sto_config_id) {
  return request({
    url: appletApi.sto + sto_config_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 更新圆通配置
 */
export function wms_yto_config_update (parameter, yto_config_id) {
  return request({
    url: appletApi.yto + yto_config_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除申通配置
 */
export function wms_sto_config_delete (sto_config_id) {
  return request({
    url: appletApi.sto + sto_config_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 删除圆通配置
 */
export function wms_yto_config_delete (yto_config_id) {
  return request({
    url: appletApi.sto + yto_config_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
