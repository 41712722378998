<template>
  <div>
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="申通">
        <sto-create-form></sto-create-form>
      </a-tab-pane>
      <a-tab-pane key="2" tab="圆通">
        <yto-create-form></yto-create-form>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import { take_inventory_read } from '@/api/take_inventory'
import StoCreateForm from '@/views/logistics-config/modules/StoCreateForm'
import YtoCreateForm from '@/views/logistics-config/modules/YtoCreateForm'

export default {
  name: 'TableList',
  components: {
    STable,
    StoCreateForm,
    YtoCreateForm
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '展示商品',
          customRender: (text) => text || '-',
          align: 'center',
          dataIndex: 'exhibition_commodity_name'
        },
        {
          title: '展示仓库',
          align: 'center',
          customRender: (text) => text || '-',
          dataIndex: 'exhibition_warehouse_name'
        },
        {
          title: 'QID',
          align: 'center',
          customRender: (text) => text || '-',
          dataIndex: 'qid'
        },
        {
          title: '数量',
          align: 'center',
          customRender: (text) => text || '-',
          dataIndex: 'count'
        },
        {
          title: '状态',
          dataIndex: 'take_inventory_status',
          customRender: (text) => this.$Dictionaries.TakeInventoryCommodityStatus[text]
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return take_inventory_read(Object.assign(parameter, this.queryParam), this.$route.query.id)
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    console.log(this.$route.query.id)
  },
  methods: {
    handleRead (record) {
      console.log(record)
    },
    handleRefund (record) {
      console.log(record)
    }
  }
}
</script>
